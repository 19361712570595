import React from 'react'
import { Link } from 'gatsby'
import './van-brochure.scss'

const VanBrochure = props => (
  <div className="van-brochure">
    <Link
      target="_blank"
      download
      to={`${process.env.GATSBY_CLOUD_URL}/image/upload/v1704453936/pdf/darc_van_price_list.pdf`}
    >
      <button className="brochure-btn">Download</button>
    </Link>
  </div>
)

export default VanBrochure
