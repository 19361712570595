import React, { useState, useEffect } from 'react'
import dynamic from '@next-tools/dynamic'
import loadable from '@loadable/component'

import '../category-carousel.scss'
import SliderArrowButton from '../../controls/slider-arrow-button'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'

const InteriorCarousel = () => {
  return (
    <Carousel
      className="interior-carousel category-carousel"
      autoPlay={true}
      infiniteLoop={true}
      interval={4000}
      transitionTime={1000}
      showThumbs={false}
      showIndicators={false}
      showArrows={false}
      showStatus={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) => (
        <SliderArrowButton onClick={onClickHandler} />
      )}
      renderArrowNext={(onClickHandler, hasNext, label) => (
        <SliderArrowButton right onClick={onClickHandler} />
      )}
    >
      <div>
        <img
          src={`/new/images/product/rig/shop/flare/RIG_FLARE_1_y3sxec.jpg`}
        />
      </div>
      <div>
        <img
          src={`/new/images/product/rig/shop/flare/RIG_FLARE_2_fafvtr.jpg`}
        />
      </div>
      <div>
        <img
          src={`/new/images/product/rig/shop/flare/RIG_FLARE_3_bnwvt5.jpg`}
        />
      </div>
    </Carousel>
  )
}

export default InteriorCarousel
