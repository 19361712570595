import React, { useState, useEffect } from 'react'
import dynamic from '@next-tools/dynamic'
import loadable from '@loadable/component'

import '../category-carousel.scss'
import SliderArrowButton from '../../controls/slider-arrow-button'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'

const FlareRingsCarousel = () => {
  const images = [
    '/new/images/product/rig/shop/flare-ring/RIG_FLARE_RING_1_yfr5jq.jpg',
    '/new/images/product/rig/shop/flare-ring/RIG_FLARE_RING_2_iqefuy.jpg',
  ]
  return (
    <Carousel
      className="interior-carousel category-carousel"
      autoPlay={true}
      infiniteLoop={true}
      interval={4000}
      transitionTime={1000}
      showThumbs={false}
      showIndicators={false}
      showArrows={false}
      showStatus={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) => (
        <SliderArrowButton onClick={onClickHandler} />
      )}
      renderArrowNext={(onClickHandler, hasNext, label) => (
        <SliderArrowButton right onClick={onClickHandler} />
      )}
    >
      {images.map(image => (
        <div>
          <img src={image} />
        </div>
      ))}
    </Carousel>
  )
}

export default FlareRingsCarousel
