import React, { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'

import Header from '../components/header/header'
import ProductBox from '../components/product/product-box'
import Category from '../components/category/category'
import Contact from '../components/contact/contact'
import Footer from '../components/layout/footer'

import ExteriorCarousel from '../components/carousel/exterior-carousel'
import InteriorCarousel from '../components/carousel/interior-carousel'
import ChassisCarousel from '../components/carousel/chassis-carousel'

import SpecsTable from '../components/table/specs-table'
import Container from '../components/layout/container'
import HeaderCarousel from '../components/carousel/header-carousel'
import CookieBox from '../components/cookie/cookie-box'
import '../components/pages/mono.scss'
import { useTranslation } from 'react-i18next'
import FlaresCarousel from '../components/carousel/rig/flares-carousel'
import RigCarousel from '../components/carousel/rig/rig-carousel'
import FlareRingsCarousel from '../components/carousel/rig/flare-rings-carousel'
import SnorkelCarousel from '../components/carousel/rig/snorkel-carousel'
import GuardCarousel from '../components/carousel/rig/guard-carousel'
import RigBrochure from '../components/brochure/van-brochure'
import RoofRackCarousel from '../components/carousel/rig/roof-rack-carousel'

const RigPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>DARC-EXP | RIG</title>
        <body className="product-page" />
      </Helmet>
      <div style={{ background: '#151510' }} className="rig-page">
        <Header />
        <CookieBox />
        <RigCarousel />
        <div className="categories-container">
          <Category
            label="Rig"
            id="newStandard"
            name={t('rigPage.newStandard.title')}
            desc={
              <>
                <p>{t('rigPage.newStandard.body.p1')}</p>
                <p>{t('rigPage.newStandard.body.p2')}</p>
              </>
            }
          />

          <FlaresCarousel />
          <Category
            name={t('rigPage.flares.title')}
            label="Rig"
            link="https://shop.darc-exp.com/en/products/rig-flares?_pos=1&_sid=131078287&_ss=r"
            desc={
              <>
                <p>{t('rigPage.flares.body.p1')}</p>
                <p>{t('rigPage.flares.body.p2')}</p>
              </>
            }
          />
          <FlareRingsCarousel />

          <Category
            name={t('rigPage.flareRings.title')}
            label="Rig"
            link="https://shop.darc-exp.com/en/products/rig-flare-rings?_pos=1&_sid=3e73cf208&_ss=r"
            desc={
              <>
                <p>{t('rigPage.flareRings.body.p1')}</p>
              </>
            }
          />
          <SnorkelCarousel />

          <Category
            name={t('rigPage.snorkel.title')}
            label="Rig"
            link="https://shop.darc-exp.com/en/products/rig-schnorchel?_pos=1&_psq=snorkel&_ss=e&_v=1.0"
            desc={
              <>
                <p>{t('rigPage.snorkel.body.p1')}</p>
                <p>{t('rigPage.snorkel.body.p2')}</p>
              </>
            }
          />
          <RoofRackCarousel />
          <Category
            name={t('rigPage.roofRack.title')}
            label="Rig"
            desc={
              <>
                <p>{t('rigPage.roofRack.body.p1')}</p>
              </>
            }
          />
          <GuardCarousel />
          <Category
            name={t('rigPage.guard.title')}
            label="Rig"
            desc={
              <>
                <p>{t('rigPage.guard.body.p1')}</p>
              </>
            }
          />

          <Category
            name={t('rigPage.doorSpacer.title')}
            label="Rig"
            link="https://shop.darc-exp.com/en/products/rig-tur-distanzstucke"
            desc={
              <>
                <p>{t('rigPage.doorSpacer.body.p1')}</p>
              </>
            }
          />
        </div>
        <Contact />
        <Footer />
      </div>
    </>
  )
}

export default RigPage
