import React, { useState, useEffect } from 'react'
import dynamic from '@next-tools/dynamic'
import loadable from '@loadable/component'

import '../category-carousel.scss'
import SliderArrowButton from '../../controls/slider-arrow-button'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'

const RoofRackCarousel = () => {
  const images = [
    '/images/rig/roof-rack/RIG_ROOF_RACK_1.jpeg',
    '/images/rig/roof-rack/RIG_ROOF_RACK_2.jpeg',
  ]
  return (
    <Carousel
      className="interior-carousel category-carousel"
      autoPlay={true}
      infiniteLoop={true}
      interval={4000}
      transitionTime={1000}
      showThumbs={false}
      showIndicators={false}
      showArrows={false}
      showStatus={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) => (
        <SliderArrowButton onClick={onClickHandler} />
      )}
      renderArrowNext={(onClickHandler, hasNext, label) => (
        <SliderArrowButton right onClick={onClickHandler} />
      )}
    >
      <div>
        <img
          alt="Roof rack carousel"
          src="/rig/roof-rack/RIG_ROOF_RACK_1.jpeg"
        />
      </div>
      <div>
        <img
          alt="Roof rack carousel"
          src="/rig/roof-rack/RIG_ROOF_RACK_2.jpeg"
        />
      </div>
    </Carousel>
  )
}

export default RoofRackCarousel
